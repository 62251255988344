import React from 'react'
import {
  Button,
  useColorModeValue
} from '@chakra-ui/react'

const Hamburger = ({ onClick, ...props }) => {
  const color = useColorModeValue('black', 'white')
  return (
    <Button colorScheme={'blackAlpha'} my={4} py={6} onClick={onClick} variant={'ghost'} color={color} {...props}>
      <svg width="45" height="22" viewBox="0 0 45 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2H45" stroke="currentColor" strokeWidth="4"/>
      <path d="M0 10.8999H45" stroke="currentColor" strokeWidth="4"/>
      <path d="M0 20H45" stroke="currentColor" strokeWidth="4"/>
      </svg>
    </Button>
  )
}

export default Hamburger
