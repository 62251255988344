import React, {useContext, useEffect, useState} from 'react'
import {Link as GatsbyLink} from 'gatsby'
import {Box, Container, Flex, Link, Heading, SimpleGrid, Switch, Button, Stack, useMediaQuery, useColorMode, useColorModeValue, useDisclosure,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
  chakra
} from '@chakra-ui/react'
import {motion, useScroll, isValidMotionProp} from 'framer-motion'
import { GlobalContext } from '../context'
import { Hamburger, Close } from '../components/UI/atoms'

const MotionHeader = chakra(motion.header, { shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children' });

const AnimateHeader = ({hidden, delay = 1, ...props}) => {
	const transition = {
		duration: 0.3 * delay,
		ease: [0.42, 0, 0.58, 1]
	}
	const variants = {
		hidden: {
			y: '-100%',
			transition
		},
		show: {
			y: 0,
			transition
		}
	}
	return <Container
    as={MotionHeader}
		initial={'hidden'}
		animate={hidden ? 'hidden' : 'show'}
		exit={'hidden'}
		variants={variants}
		{...props}
	/>
}

const DigestLink = () => <Link as={GatsbyLink} to={'/digest'} variant={'header'} activeClassName={'is-active'} mr={5}>Digest</Link>
const ContactLink = () => <Link as={GatsbyLink} to={'/info'} variant={'header'} activeClassName={'is-active'}>Info</Link>

const Header = ({ location }) => {
  const { scrolledUp, lastScroll } = useContext(GlobalContext)
	const {toggleColorMode} = useColorMode()
	const [isHumongous] = useMediaQuery('(min-width: 768px)')
	const { isOpen, onOpen, onClose } = useDisclosure()
  const background = useColorModeValue('white', 'black')
  useEffect(() => {
    if (isOpen) onClose()
  }, [location])

  const isWork = location.pathname.includes('/work/')
  const isDigest = location.pathname.includes('digest')

  let hidden
  if (isWork) {
    hidden = true
  } else if (isDigest) {
    hidden = false
  } else {
    hidden = lastScroll > 0 && !scrolledUp
  }

  return <AnimateHeader hidden={hidden} position={isDigest ? 'relative' : 'sticky'} top={0} zIndex={'overlay'}>
		<Flex justify={'space-between'}>
			<Flex alignItems={'center'} py={5}>
				<Heading as={'h1'} size={'lg'}><Link as={GatsbyLink} to={'/'} mr={5} variant={'header'}>PPKK</Link></Heading>
        <Switch
          size={'lg'}
          onChange={toggleColorMode}
          sx={{
            '.chakra-switch__track': {
              bg: useColorModeValue('black', 'white'),
              '&:focus-visible': {
                // @TODO: How to set the focus outlines globally to our `accent` color?
                // `outlineColor` isn't getting applied weirdly
                outlineColor: '#ef7f80',
              }
            },
            '.chakra-switch__thumb': { bg: useColorModeValue('white', 'black') }
          }}
        />
			</Flex>
			{!isHumongous && <Hamburger onClick={onOpen} mr={'-1rem'} />}
			{!isHumongous &&
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          isFullHeight={true}
          size={'full'}
        >
				<DrawerContent bg={background}>
          <DrawerHeader as={Flex} justify={'flex-end'}>
            <Close onClick={onClose} />
          </DrawerHeader>
					<DrawerBody as={Stack}>
						<DigestLink/>
						<ContactLink/>
					</DrawerBody>
				</DrawerContent>
			</Drawer>}
			{isHumongous && <Flex justifyContent={'end'}>
				<Box py={5}>
					<DigestLink/>
				</Box>
				<Box py={5}>
					<ContactLink/>
				</Box>
			</Flex>}
		</Flex>
	</AnimateHeader>
}

export default Header
