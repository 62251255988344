import { Container, Flex, Link} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'

const Footer = () => {
	return <Container as={'footer'}>
    <Flex py={5} h={'20vw'} alignItems={'center'} justifyContent={'space-between'}>
  		<span>PPKK {(new Date()).getFullYear()} | <Link as={GatsbyLink} to={'/digest'} variant={'ppkk'} activeClassName={'is-active'} mr={5}>Digest</Link><Link as={GatsbyLink} to={'/info'} variant={'ppkk'} activeClassName={'is-active'} mr={5}>Info</Link><Link as={GatsbyLink} to={'/legal'} variant={'ppkk'} mr={5}>Legal</Link></span>
  		{/*<Link href={'#'} fontSize={'sm'} mr={5}>Developed by Someone</Link>*/}
  	</Flex>
  </Container>
}

export default Footer
