import React from 'react'
import {
  Button,
  AccordionButton,
  useColorModeValue
} from '@chakra-ui/react'

const ArrowIcon = () => {
  return <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 0.5L7.5 6.4L1.5 0.5L0 2L7.5 9.5L15 2L13.5 0.5Z" fill="currentColor"/>
  </svg>
}

const ToggleButton = ({ onClick, readMore = 'Read more', readLess = 'TLDR', isExpanded = false, ...props }) => {
  const color = useColorModeValue('black', 'white')
  return (
    <AccordionButton
      as={Button}
      alignItems={'center'}
      justifyContent={'flex-start'}
      px={0}
      py={0}
      onClick={onClick}
      variant={'link'}
      _hover={{ bg: 'transparent' }} // AccordionButton has a bg on hover
      fontSize={'sm'}
      color={color}
      {...props}
      rightIcon={<ArrowIcon />}
      sx={{
        '.chakra-button__icon': {
          transition: 'transform 200ms linear',
          transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
        }
      }}
    >
      { isExpanded ? readLess : readMore }
    </AccordionButton>
  )
}

export default ToggleButton
