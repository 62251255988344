import React from 'react'
import {
  Button,
  useColorModeValue
} from '@chakra-ui/react'

const Close = ({ onClick, ...props }) => {
  const color = useColorModeValue('black', 'white')
  return (
    <Button colorScheme={'blackAlpha'} size={'xl'} px={2} onClick={onClick} variant={'ghost'} color={color} {...props}>
      <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.1001 1.1001L35.1001 35.1001" stroke="currentColor" strokeWidth="3"/>
      <path d="M1.1001 35.1001L35.1001 1.1001" stroke="currentColor" strokeWidth="3"/>
      </svg>
    </Button>
  )
}

export default Close
