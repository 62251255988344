import React, { useEffect, useState } from 'react'
import {useScroll} from 'framer-motion'

export const GlobalContext = React.createContext({})

export const GlobalContextProvider = ({ children }) => {
  const {scrollY} = useScroll()
  const [scrolledUp, setScrolledUp] = useState(false)
  const [lastScroll, setLastScroll] = useState(scrollY.get())
  useEffect(() => {
    return scrollY.onChange(currentScroll => {
      if (currentScroll === 0) {
        setScrolledUp(true)
      } else if (!scrolledUp && currentScroll <= lastScroll) {
        setScrolledUp(true)
      } else if (scrolledUp && currentScroll > lastScroll) {
        setScrolledUp(false)
      }
      setLastScroll(currentScroll)
    })
  }, [scrollY, scrolledUp, lastScroll])

  return (
    <GlobalContext.Provider
      value={{
        scrolledUp,
        lastScroll,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
