// the script will detect if the spec is natively supported and take action only when necessary
import smoothscroll from 'smoothscroll-polyfill'

// CustomEvent error
// https://stackoverflow.com/questions/26596123/internet-explorer-9-10-11-event-constructor-doesnt-work
(function () {
  if ( typeof window.CustomEvent === "function" ) return false //If not IE

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    var evt = document.createEvent( 'CustomEvent' )
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail )
    return evt
   }

  CustomEvent.prototype = window.Event.prototype

  window.CustomEvent = CustomEvent
})()



// the behavior seems really janky with my setup probably better to rely on the cursor
// import scrollSnapPolyfill from 'css-scroll-snap-polyfill'

// https://github.com/browser-update/browser-update
// import browserUpdate from 'browser-update'
// import "./src/browser-update.css"

export const onClientEntry = async () => {

  // Chrome 49 (release: 2016/3/2)
  // Firefox 50 (release: 2016/11/15)
  // Safari 10 (release: 2016/9/20)
  // Edge 14 (release: 2016/2/18)
  // IE 10 (release: 2012/10/26)
  /*
  browserUpdate({
    required: {f:50,o:50,s:10,c:49, e:14},
    // Specifies required browser versions
    // Browsers older than this will be notified.
    // f:22 ---> Firefox < 22 gets notified
    // Negative numbers specify how much versions behind current version.
    // c:-5 ---> Chrome < 35  gets notified if latest Chrome version is 40.
    l: false, // always english
    text: {
      'msg':'Your web browser ({brow_name}) is out of date.',
      'msgmore': 'This website relies on modern browser technology. Update your browser for more security, speed and the best experience on this site.',
      'bupdate': 'Update browser',
      'bignore': 'Ignore',
      'remind': 'You will be reminded in {days} days.',
      'bnever': 'Never show again'
    }
    // custom notification text (html)
    // Placeholders {brow_name} will be replaced with the browser name, {up_but} with contents of the update link tag and {ignore_but} with contents for the ignore link.
    // Example: "Your browser, {brow_name}, is too old: <a{up_but}>update</a> or <a{ignore_but}>ignore</a>."
    // more details (in english)

  })
  */

  smoothscroll.polyfill()

  // await import('core-js/fn/object/values')
  // await import('core-js/fn/array/find-index')
  // await import('core-js/fn/array/includes')
  // await import('core-js/es6/symbol')
  // await import('core-js/fn/symbol/iterator')
  // await import('core-js/fn/promise/try')

  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
    console.log(`👍 IntersectionObserver is polyfilled`)
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`)
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    await import(`object-fit-images`)()
    console.log(`👍 Object-fit/Object-position are polyfilled`)
  }

}
