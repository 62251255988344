import {Box, Flex} from '@chakra-ui/react'
import React from 'react'
import {GlobalContextProvider, PageContextProvider} from '../context'
import Header from './Header'
import {Global} from '@emotion/react'
import Footer from './Footer'

const Layout = ({pageContext, location, children}) => {
	return <PageContextProvider value={{location, pageContext}}>
    <GlobalContextProvider value={{ location, pageContext }}>
  		<Global
  			styles={`
          @font-face {
            font-family: 'IBM Plex Mono';
            src: url('../../fonts/IBMPlexMono-Medium.woff2') format('woff2'),
                url('../../fonts/IBMPlexMono-Medium.woff') format('woff');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
        }`}/>
      <Flex direction={'column'} minHeight={'100vh'}>
  			<Header location={location} />
  			{children}
        <Box sx={{ flexGrow: 1 }}/>
  			<Footer/>
      </Flex>
    </GlobalContextProvider>
	</PageContextProvider>
}

export default Layout
